import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; //AIzaSyBD__09hW4pjR2saUqZAyH9nyNlrPcwYAQ

export const IS_PROD = process.env.REACT_APP_IS_PROD === '1';  // DEVELOPMENT:  IS_PROD is FALSE for .env.development (0) and PRODUCTION:  IS_PROD is TRUE when .env.production - (1);
console.log(IS_PROD);
export const SAFE = 'safe';
export const SECURE = 'secure';
export const ULTRA_SECURE = 'ultra-secure';
export const HOURLY_BASE = 70;
export const ONE_WAY = 'one-way';
export const ROUND_TRIP = 'round-trip';

const ENV_PREFIX = IS_PROD ? 'prod/tux' : 'dev/tux';


export const BOOKED_RIDES_TABLE = `${ENV_PREFIX}/BookedRides`;
export const RYDERS_TABLE = `${ENV_PREFIX}/ryders`;
export const ADMINS_TABLE = `${ENV_PREFIX}/admins`;
export const CARS_TABLE = `${ENV_PREFIX}/cars`;
export const CODES_TABLE = `${ENV_PREFIX}/codes`;
export const DRIVERS_TABLE = `${ENV_PREFIX}/drivers`;
export const DRIVERS_SCHEDULE_TABLE = `${ENV_PREFIX}/DriverSchedule`;
export const CONFIRMATION_TABLE = `${ENV_PREFIX}/ConfirmationSequence`;
export const OTHER_TABLE = `${ENV_PREFIX}/other`;

export const MAX_PASSENGERS = 1000;
export const MAX_BAGS = 1000;
export const MAX_NON_STANDARD_BAGS = 1000;

// USE REGION SPECIFIC BELOW, export const MIN_BASE_FARE = 50;  // TODO:  Use to adjust minimum totalcost
export const DISCOUNT_RATE = .80;
export const DISCOUNT_START_DATE = 20240629;
export const DISCOUNT_END_DATE = 20240708;
export const DISCOUNT_MIN_COST = 40;

export const MAX_PASSENGERS_CAR_SEATS = 4;
export const MAX_PASSENGERS_SUV_SEATS = 7;
export const MAX_PASSENGERS_VAN_SEATS = 11;
export const MAX_PASSENGERS_BUS_SEATS = 20;


export const MAX_PASSENGERS_CAR_INCLUDED = 3;
export const MAX_PASSENGERS_SUV_INCLUDED = 5;
export const MAX_PASSENGERS_VAN_INCLUDED = 10;
export const MAX_PASSENGERS_BUS_INCLUDED = 20;

export const MAX_PASSENGERS_EXTRA = -1; // USE MAX PASSENGERS for now, ...WE WANT TO PROVIDE ADDITIONAL PASSENGERS AND DISCOURAGE FULL CARS DUE TO DRIVER SAFETY

export const MAX_PASSENGERS_ORIGINAL = 6;
export const MULTIPLIER_MAX_PASSENGERS = .50; // 20240908 - COST FOR ADDITIONAL PASSENGERS ABOVE INCLUDED PASSENGERS

export const MULTIPLIER_REGION_JAM = 1; //.85;
export const MULTIPLIER_REGION_SAC = 1;
export const MULTIPLIER_REGION_ABQ = 1; //.95;

// TODO:  20240908 - Validate 
export const MIN_FARE_DRIVER_SAFE = 50;
export const MIN_FARE_DRIVER_SECURE = 50;
export const MIN_FARE_DRIVER_SECURE_DETAIL = 50;
export const MIN_FARE_DRIVER_COMMERCIAL = 300;

export const COST_DRIVER_SAFE_HOURLY = 200;
export const COST_DRIVER_SECURE_HOURLY = 350;
export const COST_DRIVER_SECURE_DETAIL_HOURLY = 1000;
export const COST_DRIVER_COMMERCIAL_HOURLY = 400;

// TODO:  20240907 - Validate Prices
export const COST_RENTAL_CAR_HOURLY = 40;
export const COST_RENTAL_SUV_HOURLY = 80;
export const COST_RENTAL_VAN_HOURLY = 100;
export const COST_RENTAL_BUS_HOURLY = 1000;

// TODO:  20240908 - Validate 
export const COST_RENTAL_CAR_DAILY = 300;
export const COST_RENTAL_SUV_DAILY = 350;
export const COST_RENTAL_VAN_DAILY = 400;
export const COST_RENTAL_BUS_DAILY = 4000;

export const LEAD_TIME_REGION_JAM_HOURS = 2;
export const LEAD_TIME_REGION_SAC_HOURS = 2;
export const LEAD_TIME_REGION_ABQ_HOURS = 0;

export const LAUNCH_DATE_REGION_JAM = '2024-08-01 00:00:00'; //YYYY-MM-DD
export const LAUNCH_DATE_REGION_SAC = '2024-08-01 00:00:00'; //YYYY-MM-DD
export const LAUNCH_DATE_REGION_ABQ = '2024-10-01 00:00:00'; //YYYY-MM-DD

export const COOKIE_ID = 'ppCc';
export const COOKIE_MAX_AGE = 24 * 60 * 60;

export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;

// export const STRIPE_INTENT_URL = 'pk_test_51P4kZbB4GksnP0StidxwyNmVmKS3kcDj5loeKcdC15NkmUbeKZYUWHrxJq8yvEplmm3gArePI5t1GLF82j1kdY7X00BMyl5VRv'; // process.env.REACT_APP_STRIPE_INTENT_URL;
export const STRIPE_INTENT_URL = process.env.REACT_APP_STRIPE_INTENT_URL;
export const STRIPE_CUSTOMERS_URL = process.env.REACT_APP_STRIPE_CUSTOMERS_URL;
export const STRIPE_REFUND_URL = process.env.REACT_APP_STRIPE_REFUND_URL;
export const VIEWPORT_LIMIT = 800;

export const PHONE = '800.917.5032';
export const EMAIL = 'support@tuxrydes.com';
export const FAILED_PAYMENT_PROD = 'Unsuccessful payment, please try again ...';

export const TEXT_FIELD_THEME = createTheme({
      components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                    backgroundColor: '#F5F5F5',
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#F85804',
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#F85804',
                    },
                    [`.${outlinedInputClasses.notchedOutline}`]: {
                      borderColor: '#E0E0E0',
                    },
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ['&.Mui-focused']: {
                      color: '#F85804',
                    },
                }
            }
        }
      },
    });

