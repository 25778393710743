import { EXCURSION, HOURLY_BASE, SAFE, SECURE, ULTRA_SAFE, MAX_PASSENGERS_ORIGINAL, 
        COST_DRIVER_SAFE_HOURLY, COST_DRIVER_SECURE_HOURLY, MIN_FARE_DRIVER_SAFE,
        MULTIPLIER_REGION_JAM, MULTIPLIER_REGION_SAC, MULTIPLIER_REGION_ABQ, MAX_PASSENGERS_UPGRADE, 
        MAX_PASSENGERS_CAR_INCLUDED, MAX_PASSENGERS_SUV_INCLUDED, MAX_PASSENGERS_VAN_INCLUDED, MAX_PASSENGERS_BUS_INCLUDED,
        MAX_PASSENGERS_CAR_SEATS, MAX_PASSENGERS_SUV_SEATS, MAX_PASSENGERS_VAN_SEATS, MAX_PASSENGERS_BUS_SEATS } from './constants.js';
import dayjs from 'dayjs';
import { getVehicleType, PASSENGERS_ADDITIONAL_FARES } from './utils.js';

export function distanceToTime(distance) {
    const time = distance / 35;
    const wholePartNumber = Math.floor(time);
    const fractionPartNumber = time - wholePartNumber;
    if (fractionPartNumber < 0.25) {
        return wholePartNumber;
    } else if (fractionPartNumber < 0.5) {
        return wholePartNumber + 0.25;
    } else if (fractionPartNumber < 0.75) {
        return wholePartNumber + 0.50;
    } else if (fractionPartNumber >= 0.75) {
        return wholePartNumber + 0.75;
    } else if (wholePartNumber === 0) {
        return 0.25;
    } else {
        return wholePartNumber;
    }
}

const roundPrice = (price) => {
    return Math.round(price * 100) / 100;
};

export function allDayRate(props) {
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE) {
            return 800;
        } else if (props.serviceLevel === SECURE) {
            return 1600;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE) {
            return 1200;
        } else if (props.serviceLevel === SECURE) {
            return 1800;
        }
    } else if (props.areaOfService === 'abq') {
        if (props.serviceLevel === SAFE) {
            return 1200;
        } else if (props.serviceLevel === SECURE) {
            return 1800;
        }
    }
}

export function hourlyRate(props) {
    if (props.areaOfService === 'ja') {
        if (props.serviceLevel === SAFE) {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_JAM;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_JAM;
        }
    } else if (props.areaOfService === 'sac') {
        if (props.serviceLevel === SAFE) {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_SAC;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_SAC;
        }
    } else if (props.areaOfService === 'abq') {
        if (props.serviceLevel === SAFE) {
            return COST_DRIVER_SAFE_HOURLY * MULTIPLIER_REGION_ABQ;
        } else if (props.serviceLevel === SECURE) {
            return COST_DRIVER_SECURE_HOURLY * MULTIPLIER_REGION_ABQ;
        }
    }
}

const discountPeriods = [
    {
        startDate: '2024/06/29',
        endDate: '2024/07/08',
        discount: 0.80,
        minCost: 40,
    }
];

// TODO:  Use for testing, implement .env to address temp elevation to export for testing
function discount(totalCost, props) {
    const today = dayjs();
    for (const discountPeriod of discountPeriods ) {
        const startDate = dayjs(discountPeriod.startDate);
        const endDate = dayjs(discountPeriod.endDate);
        if (today.isAfter(startDate) && today.isBefore(endDate)) {
            const discountedCost =  totalCost * discountPeriod.discount;
            return (discountPeriod?.minCost === undefined || discountedCost > discountPeriod.minCost) ? discountedCost: discountPeriod.minCost;
        }
    }
    return totalCost;
}

function code(totalCost, codeInfo) {
    console.log(codeInfo);
    if (!codeInfo || !codeInfo?.type) {
        return totalCost;
    }
    const type = codeInfo.type;
    console.log(type);
    if (type === 'discount') {
        const discountType = codeInfo?.discountType || '';
        const discountAmount = codeInfo?.discountAmount ? parseFloat(codeInfo?.discountAmount): 0;
        const used = codeInfo?.used ? parseInt(codeInfo?.used): 0;
        const quantity = codeInfo?.quantity ? parseInt(codeInfo?.quantity): 0;
        if (quantity === 0 || used < quantity) {
            if (discountType === 'percentage') {
                return totalCost * (discountAmount / 100);
            } else if (discountType === 'amount') {
                return totalCost - discountAmount;
            }
        }
    }
    return totalCost;

}

function passenger(totalCost, props) {
    let passengerCost = 0;    
    const passengerCount = props.rideData[0].passengers;
    console.log(passengerCount);
    console.log (MAX_PASSENGERS_CAR_SEATS)
    console.log (passengerCount <= MAX_PASSENGERS_CAR_SEATS)
    
    if (passengerCount <= MAX_PASSENGERS_CAR_SEATS) {
        passengerCost = (MAX_PASSENGERS_CAR_INCLUDED - passengerCount) > 0 ? totalCost * (MAX_PASSENGERS_CAR_INCLUDED - passengerCount) : 0;
    } else if (passengerCount <= MAX_PASSENGERS_SUV_SEATS) {
        passengerCost = (MAX_PASSENGERS_SUV_INCLUDED - passengerCount) > 0 ? totalCost * (MAX_PASSENGERS_SUV_INCLUDED - passengerCount) : 0;
    } else if (passengerCount <= MAX_PASSENGERS_VAN_SEATS) {
        passengerCost = (MAX_PASSENGERS_VAN_INCLUDED - passengerCount) > 0 ? totalCost * (MAX_PASSENGERS_VAN_INCLUDED - passengerCount) : 0;
    } else if (passengerCount <= MAX_PASSENGERS_BUS_SEATS) {
        // For buses, the cost is simply the totalCost multiplied by the number of passengers
        passengerCost = totalCost * passengerCount; // Each passenger pays the base fare
    } else {
        // throw new Error('Passenger count exceeds maximum capacity for any vehicle type');
    }

    return totalCost + passengerCost; // Return the total cost including base fare
}

function vehicle(totalCost, props) {
    // use for fixed vehicle rental costs
    const passengerCount = props.rideData[0].passengers;
    let vehicleCost = totalCost;
    const vehicleType = getVehicleType(passengerCount);

    //console.log (vehicleType);
    // console.log(props.rideData[0].passengers);

        switch (vehicleType.toUpperCase()) {
        case "CAR":
            console.log("You selected a car.");
            // additional passengers for pricing  
            // vehicleCost = totalCost - MIN_FARE_DRIVER_SAFE;
            vehicleCost = Math.max(PASSENGERS_ADDITIONAL_FARES * totalCost, totalCost - MIN_FARE_DRIVER_SAFE);
            break;
        case "SUV":
            console.log("You selected an SUV.");
            break;
        case "VAN":
            console.log("You selected a van.");
            break;
        case "BUS":
            console.log("You selected a bus.");
            break;
        default:
            console.log("Unknown vehicle type.");
        }

        console.log(PASSENGERS_ADDITIONAL_FARES);
    return totalCost;
}

function region(totalCost, props) {
    const regionCost = totalCost; // totalCost + (totalCost * 0); // 2.00); 

    return regionCost;
}

// Export the discount function only in test environments
if (process.env.NODE_ENV === 'test') {
    module.exports.discount = discount;
    module.exports.passenger = passenger;
    module.exports.vehicle = vehicle;
    module.exports.region = region;
}

export function calculatePrice(props) {
    let totalCost = 0;
    // let passengerCount = props.rideData[0].passengers;

    for (const rideDataItem of props.rideData) {
        if (rideDataItem.allDay) {
            totalCost = totalCost + allDayRate({areaOfService: props.areaOfService, serviceLevel: props.serviceLevel});
        } else {
            if (!rideDataItem.estimatedDuration) {
                continue;
            }
            const hours = rideDataItem.estimatedDuration.value / (60 * 60);
            totalCost = totalCost + hourlyRate({areaOfService: props.areaOfService, serviceLevel: props.serviceLevel}) * hours;
        }
    }

    totalCost = vehicle(totalCost, props);
    totalCost = discount(totalCost, props);
    // totalCost = region(totalCost, props); 
    // totalCost = passenger(totalCost, props);
    // totalCost = props.rideData.distance;
    totalCost = code(totalCost, props?.codeInfo);
    return roundPrice(totalCost);

}
