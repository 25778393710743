import dayjs from 'dayjs';

import { query, collection, where, getDoc, getDocs, doc, runTransaction } from "firebase/firestore";
import { SAFE, SECURE, ULTRA_SECURE, DRIVERS_TABLE, RYDERS_TABLE, CODES_TABLE,
    MAX_PASSENGERS_CAR_SEATS, MAX_PASSENGERS_SUV_SEATS, MAX_PASSENGERS_VAN_SEATS, MAX_PASSENGERS_BUS_SEATS,
    MAX_PASSENGERS_CAR_INCLUDED, MAX_PASSENGERS_SUV_INCLUDED, MAX_PASSENGERS_VAN_INCLUDED, MAX_PASSENGERS_BUS_INCLUDED, 
    COST_RENTAL_CAR_DAILY, COST_RENTAL_SUV_DAILY, COST_RENTAL_VAN_DAILY, COST_RENTAL_BUS_DAILY,
    LAUNCH_DATE_REGION_JAM, LAUNCH_DATE_REGION_SAC, LAUNCH_DATE_REGION_ABQ} from './constants';

export let PASSENGERS_ADDITIONAL_FARES = 0;

export async function  getUserInfo(db, userId) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('userId', '==', userId)));
    if (ryderDoc.docs.length === 1) {
        const ryder = ryderDoc.docs[0].data();
        return {userId: userId, id: ryderDoc.docs[0].id, firstName: ryder.firstName, lastName: ryder.lastName, phoneNumber: ryder.phoneNumber, email: ryder.email };
    } else if (ryderDoc.docs.length > 1) {
        throw new Error('Invalid user');
    }
    return null;
}

export async function  userExists(db, email) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('email', '==', email)));
    if (ryderDoc.docs.length === 0) {
        return false;
    }
    return true;
}

export async function getDriverId(db) {
    const driversDoc = await getDocs(collection(db, DRIVERS_TABLE));
    if (driversDoc.docs.length > 0) {
        const driver = driversDoc.docs[0];
        return driver.id;
    }
    return null;
}

export async function getDriverInfo(db, driverId) {
    const driverDoc = await getDoc(doc(db, DRIVERS_TABLE, driverId));
    if (driverDoc) {
        const driver = driverDoc.data();
        return {firstName: driver.firstName, lastName: driver.lastName, phoneNumber: driver.phoneNumber, email: driver.email };
    }
    return null;
}

export async function getCode(db, codeName) {
    const codesDoc = await getDocs(query(collection(db, CODES_TABLE), where('code', '==', codeName)));
    if (codesDoc.docs.length === 1) {
        const code = codesDoc.docs[0].data();
        return {id: codesDoc.docs[0].id, ...code};
    }
    return null;
}

export async function incrementCodeUsed(db, codeId) {
    if (!codeId) {
        return;
    }
    const codeDocRef = doc(db, CODES_TABLE, codeId);
    const used = await runTransaction(db, async (transaction) => {
        const codeDoc = await transaction.get(codeDocRef);
         if (!codeDoc.exists()) {
           return;
         }
         const newUsed = parseInt(codeDoc.data().used) + 1;
         transaction.update(codeDocRef, { used: `${newUsed}` });
    });
}

export function getRideStatus(ride) {
    if (!ride.legs) {
        return 'UNKNOWN';
    }
    if (ride.status === 'CANCELED') {
        return 'CANCELED';
    }
    const nowDateTime = new Date().getTime();
    let legNotStarted = ride.legs.length;
    for (const leg of ride.legs) {
        const pickUpDateTime = new Date(leg.pickUpDateTime).getTime();
        if (pickUpDateTime < nowDateTime) {
            legNotStarted--;
        }
    }
    if (legNotStarted === ride.legs.length) {
        return 'NOT STARTED';
    } else if (legNotStarted === 0) {
        return 'COMPLETED';
    } else {
        return 'IN PROGRESS';
    }
}

export function getServiceLevel(serviceLevel) {
    if (serviceLevel === SAFE) {
        return 'Safe';
    } else if (serviceLevel === SECURE) {
        return 'Secure';
    } else if (serviceLevel === ULTRA_SECURE) {
        return 'Ultra-Secure';
    } else {
        return serviceLevel;
    }
}

export function setUpPhoneNumber(old, value) {
    if ((old === null || old === '') && value.length >= 10) {
        return `${value.replace('+', '+1')}`;
    }
    return value;
}

export function printDate(dateString) {
   const date =  new Date(dateString);
   if (isNaN(date)) {
    return 'N/A';
   }
   return date.toLocaleString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true});
}

export function getVehicleType(passengerCount) {
   if (passengerCount <= MAX_PASSENGERS_CAR_SEATS) {
        PASSENGERS_ADDITIONAL_FARES = passengerCount - MAX_PASSENGERS_CAR_INCLUDED;
       return 'car';
   } else if (passengerCount <= MAX_PASSENGERS_SUV_SEATS) {
        PASSENGERS_ADDITIONAL_FARES = passengerCount - MAX_PASSENGERS_SUV_INCLUDED;
       return 'suv';
   } else if (passengerCount <= MAX_PASSENGERS_VAN_SEATS) {
        PASSENGERS_ADDITIONAL_FARES = passengerCount - MAX_PASSENGERS_VAN_INCLUDED;
       return 'van';
   } else {
       return 'bus'; 
   }
}

export function getVehicleCount(vehicleType, passengerCount) {
    let maxPassengers;
    
    switch (vehicleType.toLowerCase()) {
        case 'car':
            maxPassengers = MAX_PASSENGERS_CAR_SEATS;
            break;
        case 'suv':
            maxPassengers = MAX_PASSENGERS_SUV_SEATS;
            break;
        case 'van':
            maxPassengers = MAX_PASSENGERS_VAN_SEATS;
            break;
        case 'bus':
            maxPassengers = MAX_PASSENGERS_BUS_SEATS;
            break;
        default:
            return 0; 
    }

    if (passengerCount <= maxPassengers) {
        return 1; 
    } else {
        const fullGroups = Math.floor(passengerCount / maxPassengers);
        const remainder = passengerCount % maxPassengers;

        return fullGroups + (remainder > 0 ? 1 : 0);
    }
}

export function getVehicleRentalDaily(passengerCount) {
    if (passengerCount <= MAX_PASSENGERS_CAR_SEATS) {
        return COST_RENTAL_CAR_DAILY;
    } else if (passengerCount <= MAX_PASSENGERS_SUV_SEATS) {
        return COST_RENTAL_SUV_DAILY;
    } else if (passengerCount <= MAX_PASSENGERS_VAN_SEATS) {
        return COST_RENTAL_VAN_DAILY;
    } else {
        return COST_RENTAL_BUS_DAILY; // Treat as bus for any count above 11
    }
 }

 export function getInitalDate(serviceArea) {
    const jaStartDate = dayjs.tz(LAUNCH_DATE_REGION_JAM, 'America/Los_Angeles');
    const sacStartDate = dayjs.tz(LAUNCH_DATE_REGION_SAC, 'America/Los_Angeles');
    const abqStartDate = dayjs.tz(LAUNCH_DATE_REGION_ABQ, 'America/Los_Angeles');

    const today = dayjs();

    if (serviceArea === 'ja') {
        return jaStartDate.isBefore(today) ? today: jaStartDate;
    } else if (serviceArea === 'sac') {
       return sacStartDate.isBefore(today) ? today: sacStartDate;
    } else if (serviceArea === 'abq') {
       return abqStartDate.isBefore(today) ? today: abqStartDate;
    } else {
       return today;
    }
 }