import './BookRide.css';
import React, { useState, useContext, useEffect, ChangeEvent, Fragment, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import {Map, Marker, useMapsLibrary} from '@vis.gl/react-google-maps';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import MuiPhoneNumber from 'mui-phone-number';

import { ThemeProvider, createTheme, makeStyles } from '@mui/material/styles';

import { getFirestore, addDoc, collection, doc, runTransaction } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import AutoCompleteTextField from '../components/AutoCompleteTextField';
import SignIn from './SignIn';
import Signup from './Signup';
import RideLeg from '../components/RideLeg';
import StripePayment from './StripePayment';
import RideMap from '../components/RideMap';

import TUXActionButton from '../components/TUXActionButton';
import TUXAppBarButton from '../components/TUXAppBarButton';
import TUXAccordionHeader from '../components/TUXAccordionHeader';
import TUXTextField from '../components/TUXTextField';

import { calculatePrice } from '../libs/prices.js';
import { getDriverId, getUserInfo, getServiceLevel, getVehicleType, setUpPhoneNumber, printDate, getCode, incrementCodeUsed } from '../libs/utils.js';
import { TEXT_FIELD_THEME, BOOKED_RIDES_TABLE, CONFIRMATION_TABLE, SAFE, SECURE, ULTRA_SECURE, VIEWPORT_LIMIT, PHONE, IS_PROD, MULTIPLIER_MAX_PASSENGERS, MAX_PASSENGERS_ORIGINAL, ONE_WAY, ROUND_TRIP } from '../libs/constants.js';

function BookRide() {
  const params = useParams();
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(params.serviceArea ? 'details' : 'area');
  const [code, setCode] = useState('');
  const [codeInfo, setCodeInfo] = useState(null);
  const {auth, setAuth} = useContext(AuthContext);

  const [metadata, setMetadata] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [payment, setPayment] = useState(false);
  const [userInfo, setUserInfo] = useState({
            firstName: auth?.userInfo?.firstName || '',
            lastName: auth?.userInfo?.lastName || '',
            email: auth?.userInfo?.email || '',
            phoneNumber: auth?.userInfo?.phoneNumber || ''});
  const [phone, setPhone] = useState(auth?.userInfo?.phoneNumber || '');
  const [price, setPrice] = useState(0);
  const [unSelectedFee, setUnSelectedFee] = useState(0);
  const [fee, setFee] = useState(0);
  const [tip, setTip] = useState(0);
  const [confirmationCode, setConfirmationCode] = useState(0);
  //const [driverId, setDriverId] = useState(null);
  const [serviceLevel, setServiceLevel] = useState('secure');
  const [consentToTexting, setConsentToTexting] = useState(false);
  const [legs, setLegs] = useState([]);
  const [areaOfService, setAreaOfService] = useState(params.serviceArea || null);
  const [tripType, setTripType] = useState(ONE_WAY);

  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [submitProcessing, setSubmitProcessing] = useState(false);

  const [previousRideData, setPreviousRideData] = useState(null);

  const {firebaseApp} = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);

  const nav = useNavigate();

  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const onChangeServiceLevel = (event: ChangeEvent) => {
     setServiceLevel(event.target.value);
     const newFee = calculatePrice({rideData: rideDataList, serviceLevel: event.target.value, areaOfService: areaOfService, codeInfo: codeInfo});
     setFee(newFee);
     setPrice(newFee + tip);

     const otherArea = event.target.value === SAFE? SECURE: SAFE;
     setUnSelectedFee(calculatePrice({rideData: rideDataList, serviceLevel: otherArea, areaOfService: areaOfService, codeInfo: codeInfo}));
  };

  const changeServiceLevel = (level) => {
       setServiceLevel(level);
       const newFee = calculatePrice({rideData: rideDataList, serviceLevel: level, areaOfService: areaOfService, codeInfo: codeInfo });
       const tmpLegs = getLegs();

       setFee(newFee);
       setPrice(newFee + tip);

       const otherArea = level === SAFE? SECURE: SAFE;
       setUnSelectedFee(calculatePrice({rideData: rideDataList, serviceLevel: otherArea, areaOfService: areaOfService, codeInfo: codeInfo}));
    };

  const backToMetadata = () => {
    setPayment(false);
    setMetadata(true);
  };

  const getLegs = () => {
    return rideDataList.map((rideData, i) => {
        const pickUpDateTime = dayjs.tz(`${rideData.date.year()}/${rideData.date.month() + 1}/${rideData.date.date()} ${rideData.time.hour()}:${rideData.time.minute()}`, areaOfService === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
        return {
           from: rideData.from,
           to: rideData.to,
           fromPlaceId: rideData.fromPlaceId,
           toPlaceId: rideData.toPlaceId,
           pickUpDateTime: pickUpDateTime,
           passengers: rideData.passengers,
           vehicleType: getVehicleType(rideData.passengers),
           bags: {
               luggage: rideData.bags.luggage,
               overSizedBags: rideData.bags.overSizedBags,
               irregularBags: rideData.bags.irregularBags
               },
           allDay: rideData.allDay,
           estimatedDuration: rideData.estimatedDuration,
        };
    });
  };

  const confirmCode = async () => {
    if (confirmationCode === 0) {
        const seqDocRef = doc(db, CONFIRMATION_TABLE, "only");
        const code = await runTransaction(db, async (transaction) => {
            const seqDoc = await transaction.get(seqDocRef);
             if (!seqDoc.exists()) {
               throw "Document does not exist!";
             }
             const newCode = seqDoc.data().sequence + 1;
             transaction.update(seqDocRef, { sequence: newCode });
             return newCode;

        });
        setConfirmationCode(code);
    }
  };

  const submit = async (paymentConfirm) => {
    setMetadata(false);

    const tmpLegs = getLegs();
    setLegs(tmpLegs);
    addDoc(collection(db, BOOKED_RIDES_TABLE),
        {
          legs: tmpLegs,
          primary: {
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    email: userInfo.email,
                    phone: userInfo.phoneNumber,
                    consentToTexting: consentToTexting
                    },
          status: 'ACTIVE',
          ryder: auth.userInfo.userId,
          level: serviceLevel,
          price: {total: price, fee: fee, tip: tip},
          confirmationCode: confirmationCode,
          code: code,
          codeInfo: codeInfo,
          driver: null,
          paymentConfirm: paymentConfirm,
        }
      ).then(async ()=> {
        incrementCodeUsed(db, codeInfo?.id);
        setSubmitProcessing(false);
        setIsSubmitted(true);
    });

  };

  const [rideDataList, setRideDataList] = useState([{}]);

  const addLeg = ()=> {
    let updatedRideDataList = []
    for (const rideData of rideDataList) {
        updatedRideDataList.push(rideData);
    }
    updatedRideDataList.push({});
    setRideDataList(updatedRideDataList);
  };

  const removeLeg = (index) => {
      let updatedRideDataList = [];
      for (let i = 0; i < rideDataList.length; i++) {
          if (i !== index) {
              updatedRideDataList.push(rideDataList[i]);
          }
      }
      setRideDataList(updatedRideDataList);
      if (serviceLevel && areaOfService) {
          const newFee = calculatePrice({rideData: updatedRideDataList, serviceLevel: serviceLevel, areaOfService: areaOfService, codeInfo: codeInfo});
          setFee(newFee);
          setPrice(newFee + tip);
          const otherArea = serviceLevel === SAFE? SECURE: SAFE;
          setUnSelectedFee(calculatePrice({rideData: updatedRideDataList, serviceLevel: otherArea, areaOfService: areaOfService, codeInfo: codeInfo}));
      }
  };

  const onChangeRideLeg = (index, updatedRideData) => {
    let updatedRideDataList = [];
    for (let i = 0; i < rideDataList.length; i++) {
        if (i === index) {
            updatedRideDataList.push(updatedRideData);
        } else {
            updatedRideDataList.push(rideDataList[i]);
        }
    }
    setRideDataList(updatedRideDataList);
    if (serviceLevel && areaOfService) {
        const newFee = calculatePrice({rideData: updatedRideDataList, serviceLevel: serviceLevel, areaOfService: areaOfService, codeInfo: codeInfo});
        setFee(newFee);
        setPrice(newFee + tip);
        const otherArea = serviceLevel === SAFE? SECURE: SAFE;
        setUnSelectedFee(calculatePrice({rideData: updatedRideDataList, serviceLevel: otherArea, areaOfService: areaOfService, codeInfo: codeInfo}));
    }
    if (updatedRideDataList.length > 1) {
        setPreviousRideData(updatedRideDataList[0]);
    }
  };

  const handleSignInOpen = () => {
    setSignInOpen(true);
  };

  const handleSignUpOpen = () => {
     setSignUpOpen(true);
  };

  const handleTip = (event) => {
    let newTip = parseInt(event.target.value);
    if (isNaN(newTip)) {
        setTip('');
        setPrice(fee);
    } else {
        setTip(newTip);
        setPrice(fee + newTip);
    }
  };

  const userLoggedIn = () =>  {
      return !(!auth || !auth?.loggedIn || auth?.userInfo?.userId === undefined);
    }

  const isValid = () => {
    return (auth && auth?.loggedIn && auth?.userInfo?.userId !== undefined);
  }

  useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
  });

  const detailsCompleted = () => {
    let completed = true;
    for(const rideData of rideDataList ) {
        if (rideData?.estimatedDuration?.value === undefined ||
            rideData?.date === undefined ||
            rideData?.time === undefined) {
            completed = false;
        }
    }
    return completed;
  };

  const detailsCompletedHeader = () => {
    for(const rideData of rideDataList ) {
        return `Pick-up location - ${rideData.from}`;
    }
    return '';
  };

  const levelCompleted = () => {
    return (serviceLevel !== undefined && serviceLevel !== null)
  };

  const areaOfServiceCompleted = () => {
    return (areaOfService !== null);
  };

  const passengerCompleted = () => {
    return (userInfo?.firstName !== '' &&
            userInfo?.lastName !== '' &&
            userInfo?.email !== '' &&
            userInfo?.phoneNumber !== '' &&
            userLoggedIn())
  };
  const isSmallViewPort = (viewPortWidth < VIEWPORT_LIMIT);

  const oneWaySetup = () => {
    if (rideDataList.length !== 1) {
        removeLeg(1);
    }
    setTripType(ONE_WAY);
    setPreviousRideData(null);
  };

  const roundTripSetup = () => {
    if (rideDataList.length < 2) {
      addLeg();
   }
   setTripType(ROUND_TRIP);
   setPreviousRideData(rideDataList[0]);
  };

  const applyPromoCode = async () => {
    const newCodeInfo = await getCode(db, code);
    console.log(newCodeInfo);
    setCodeInfo(newCodeInfo);

    const newFee = calculatePrice({rideData: rideDataList, serviceLevel: serviceLevel, areaOfService: areaOfService, codeInfo: newCodeInfo});
    setFee(newFee);
    setPrice(newFee + tip);

    const otherArea = serviceLevel === SAFE? SECURE: SAFE;
    setUnSelectedFee(calculatePrice({rideData: rideDataList, serviceLevel: otherArea, areaOfService: areaOfService, codeInfo: newCodeInfo}));
  };

  return (
    <div className="BookRide">
    <ThemeProvider theme={TEXT_FIELD_THEME}>
       {metadata && ( <Stack spacing={1}>
        <Accordion variant="outlined" expanded={step === 'area'} onChange={(event) => setStep('area')}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             id="area"
           >
               <TUXAccordionHeader
                    sectionHeader={"Service Location"}
                    stepId={'area'}
                    currentStep={step}
                    stepCompleted={areaOfService !== null && areaOfService !== ''}
                    completedText={`Service Location - ${areaOfService === 'ja' ? 'Jamaica' : areaOfService === 'abq' ? 'Albuquerque' : 'Sacramento, CA'}`}
                    text={'Select a service location for your ryde.'} />
           </AccordionSummary>
           <AccordionDetails>
               <Stack direction="column" spacing={4}>
                   <Stack spacing={4} direction="row">
                       <TUXAppBarButton bordered={(areaOfService !== 'ja')} text="Jamaica"  color={areaOfService === 'ja' ?"orange": "white"} onClick={() => setAreaOfService('ja')}/>
                       <TUXAppBarButton bordered={(areaOfService !== 'sac')} text="Sacramento, CA" color={areaOfService === 'sac' ?"orange": "white"} onClick={() => setAreaOfService('sac')}/>
                       <TUXAppBarButton bordered={(areaOfService !== 'abq')} text="Albuquerque, NM"  color={areaOfService === 'abq' ?"orange": "white"} onClick={() => setAreaOfService('abq')}/>
                   </Stack>

                   <div className="ContinueButton">
                       <TUXActionButton flex={true} disabled={!areaOfServiceCompleted()} onClick={() => setStep('details')}>Continue</TUXActionButton>
                   </div>
               </Stack>
           </AccordionDetails>
       </Accordion>

        <Accordion variant="outlined" expanded={step === 'details'} onChange={(event) => setStep('details')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="details">
                <TUXAccordionHeader
                    sectionHeader={"Ride Details"}
                    stepId={'details'}
                    currentStep={step}
                    stepCompleted={detailsCompleted()}
                    completedText={detailsCompletedHeader()}
                    text={'Select location for ride details.'} />
            </AccordionSummary>
            <AccordionDetails>
                    <Stack spacing={2} direction="column">
                        <div>
                           <Stack spacing={4} direction="row">
                               <TUXAppBarButton bordered={(tripType !== ONE_WAY)} text="One Way"  color={tripType === ONE_WAY ?"orange": "white"} onClick={() => oneWaySetup()}/>
                               <TUXAppBarButton bordered={(tripType !== ROUND_TRIP)} text="Round Trip" color={tripType === ROUND_TRIP ?"orange": "white"} onClick={() => roundTripSetup()}/>
                           </Stack>
                        </div>
                    {
                        rideDataList.map( (rideData, i) => {
                            return (
                                <Stack key={`rideLegsStack_${i}`}>
                                    <Card variant="outlined" key={`rideLegsCard_${i}`}>
                                        <CardContent key={`rideLegsCardContent_${i}`} >
                                        {tripType === ROUND_TRIP && i === 1 && (
                                            <RideLeg key={`rideData_${i}`} pickUp={previousRideData.to} dropOff={previousRideData.from} pickUpPlaceId={previousRideData.toPlaceId} dropOffPlaceId={previousRideData.fromPlaceId} onChange={(updatedRideData) => { onChangeRideLeg( i, updatedRideData); }} region={areaOfService}/>)}
                                        {(tripType === ONE_WAY || i !== 1) && (
                                            <RideLeg key={`rideData_${i}`} onChange={(updatedRideData) => { onChangeRideLeg( i, updatedRideData); }} region={areaOfService}/>)}
                                        </CardContent>
                                    </Card>
                                </Stack>
                            );
                        })
                    }
                    <div className="ContinueButton">
                        <TUXActionButton flex={true}  onClick={() => setStep('level')} disabled={!detailsCompleted()}>Continue</TUXActionButton>
                    </div>
                    </Stack>

            </AccordionDetails>
        </Accordion>

        <Accordion variant="outlined" expanded={step === 'level'} onChange={(event) => setStep('level')} disabled={!detailsCompleted()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="level"
            >
                <TUXAccordionHeader
                    sectionHeader={"Service Level"}
                    stepId={'level'}
                    currentStep={step}
                    stepCompleted={levelCompleted() && detailsCompleted()}
                    completedText={`Service level - ${getServiceLevel(serviceLevel)}`}
                    text={'Select the level of security you want.'} />
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="column" spacing={4}>
                    <Stack spacing={4} direction={!isSmallViewPort? "row": "column"}>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={4} direction="row">
                                <TUXAppBarButton bordered={(serviceLevel !== SAFE)} text={serviceLevel === SAFE ? `${getServiceLevel(SAFE)} - ${priceFormatter.format(fee)}`: `${getServiceLevel(SAFE)} - ${priceFormatter.format(unSelectedFee)}` } color={serviceLevel === SAFE ?"orange": "white"} onClick={() => changeServiceLevel(SAFE)}/>
                                <TUXAppBarButton bordered={(serviceLevel !== SECURE)} text={serviceLevel === SECURE ? `${getServiceLevel(SECURE)} - ${priceFormatter.format(fee)}`: `${getServiceLevel(SECURE)} - ${priceFormatter.format(unSelectedFee)}` } color={serviceLevel === SECURE ?"orange": "white"} onClick={() => changeServiceLevel(SECURE)}/>
                            </Stack>
                            {serviceLevel === SAFE && (
                            <div className="ServiceLevelDescription">
                                <div className="ServiceLevelStar"></div>
                                <span><p>Our Safe service is a high-quality ride with a vetted professional driver and a nice, clean, well-maintained vehicle.</p></span>
                            </div>)}
                            {serviceLevel === SECURE && (
                            <div className="ServiceLevelDescription">
                                <div className="ServiceLevelStar"></div>
                                <span><p>Our Secure service is a high-quality ride with an <b>armed</b> professional driver and a nice, clean, well-maintained vehicle.</p></span>
                            </div>)}
                        </Stack>
                    </Stack>
                    <div className="ContinueButton">
                        <TUXActionButton flex={true}  onClick={() => setStep('passenger')} disabled={!levelCompleted()}>Continue</TUXActionButton>
                    </div>
                </Stack>
            </AccordionDetails>
        </Accordion>

        <Accordion variant="outlined" expanded={step === 'passenger'} onChange={(event) => setStep('passenger')} disabled={!levelCompleted() || !detailsCompleted()}>
             <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               id="passenger"
             >
                 <TUXAccordionHeader
                         sectionHeader={"Primary passenger details"}
                         stepId={'passenger'}
                         currentStep={step}
                         stepCompleted={passengerCompleted()}
                         completedText={`Name - ${userInfo?.firstName} ${userInfo?.lastName}`}
                         text={'Enter the primary passenger information.'} />
             </AccordionSummary>
        <AccordionDetails>
                <Stack spacing={4} direction="column">
                    <h4>Primary Passenger Information</h4>
                    <Stack spacing={2} direction={!isSmallViewPort ? "row": "column"}>
                        <TUXTextField label="First Name" variant="outlined" type="text" value={userInfo.firstName} onChange={(event) => { setUserInfo({...userInfo, firstName: event.target.value}); }} InputLabelProps={{ shrink: (userInfo.firstName !== '')}} sx={{minWidth: 250, maxWidth: 300}}/>
                        <TUXTextField label="Last Name" variant="outlined" type="text" value={userInfo.lastName} onChange={(event) => { setUserInfo({...userInfo, lastName: event.target.value}); }} InputLabelProps={{ shrink: (userInfo.lastName !== '')}} sx={{minWidth: 250, maxWidth: 300}}/>
                        <TUXTextField label="Email" variant="outlined" type="email" value={userInfo.email} onChange={(event) => { setUserInfo({...userInfo, email: event.target.value}); }} InputLabelProps={{ shrink: (userInfo.email !== '')}} sx={{minWidth: 250, maxWidth: 300}}/>
                        <MuiPhoneNumber disableAreaCodes={true} variant="outlined" label="Phone Number" defaultCountry={'us'} value={userInfo.phoneNumber} onChange={(value) => { let newPhoneNumber = setUpPhoneNumber(userInfo.phoneNumber, value);  setUserInfo({...userInfo, phoneNumber: newPhoneNumber}); }} sx={{minWidth: 250, maxWidth: 300}}/>
                    </Stack>
                    <div>
                        <Checkbox checked={consentToTexting}  onChange={(event) => { setConsentToTexting(event.target.checked);}} /> I consent to receive text messages about my ride. Message and data rates may apply.
                    </div>
                    { !userLoggedIn() && (
                        <Stack>
                            <Stack spacing={!isSmallViewPort ? 10: 2} direction={!isSmallViewPort ? "row": "column"}>
                                <Fragment>
                                    <Button onClick={handleSignUpOpen}>Create account</Button>
                                    <Dialog open={signUpOpen} onClose={()=> setSignUpOpen(false)} fullScreen={isSmallViewPort}>
                                        <DialogContent sx={{width: !isSmallViewPort?450:375, padding: 0}}>
                                            <Signup afterSignUp={(userInfo)=> {setUserInfo(userInfo); setSignUpOpen(false);}} closeAction={() => setSignUpOpen(false)}/>
                                        </DialogContent>
                                    </Dialog>
                                </Fragment>
                                <Fragment>
                                    <Button onClick={handleSignInOpen}>I Already Have An Account</Button>
                                    <Dialog open={signInOpen} onClose={()=> setSignInOpen(false)} fullScreen={isSmallViewPort} >
                                        <DialogContent sx={{width: !isSmallViewPort?450:375, padding: 0}}>
                                            <SignIn afterSignIn={(userInfo)=> {setUserInfo(userInfo); setSignInOpen(false);}} closeAction={() => setSignInOpen(false)}/>
                                        </DialogContent>
                                    </Dialog>
                                </Fragment>
                            </Stack>
                            <div>Your account contains your ride history, receipts and allows you to make changes directly.</div>
                        </Stack>) }
                        <div className="ContinueButton">
                            <TUXActionButton flex={true}  onClick={async() => {setStep('payment'); await confirmCode();} }  disabled={!passengerCompleted()}>Continue</TUXActionButton>
                        </div>
                </Stack>
            </AccordionDetails>
        </Accordion>

        <Accordion variant="outlined" expanded={step === 'payment'} onChange={async (event) => {setStep('payment'); await confirmCode();}} disabled={!passengerCompleted() || !levelCompleted() || !detailsCompleted()}>
             <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               id="payment"
             >
              <TUXAccordionHeader
                   sectionHeader={"Payment details"}
                   stepId={'payment'}
                   currentStep={step}
                   stepCompleted={passengerCompleted()}
                   completedText={`Total Due: ${!isNaN(fee) ? priceFormatter.format(fee + tip): '0'}`}
                   text={'Enter the primary passenger information.'} />
            </AccordionSummary>
            <AccordionDetails>
                    <div className="PromoCode">
                        <TUXTextField label="Promo Code" variant="outlined" value={code} onChange={(event) => setCode(event.target.value)} sx={{minWidth: 200, maxWidth: 250}}/>
                        <div className="ApplyButton">
                            <TUXActionButton flex={true} onClick={() => applyPromoCode()} disabled={ code === '' }>Apply</TUXActionButton>
                        </div>
                    </div>
                    <StripePayment price={price} paymentSubmitted={submit} valid={isValid} email={auth?.user?.email}
                        confirmationCode={confirmationCode} live={IS_PROD}
                        presubmit={() => setSubmitProcessing(true)}
                        postsubmit={() => setSubmitProcessing(false)}/>

            </AccordionDetails>
        </Accordion>
        <Fragment>
                    <Dialog open={submitProcessing}>
                        <DialogContent>
                            <div className="ProcessPayment">
                                <p>Processing Payment</p>
                                <CircularProgress size={100} style={{color: "#F85804"}}/>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Fragment>
        </Stack>

        )}
        {isSubmitted && (
            <div className="Confirmation">
                <div className="ThankYou">Thank You for Booking with TuxRydes</div>
                <div className="TheSmallPrint">100% of Tips Go to Drivers</div>
                <div className="ConfirmationCode">Confirmation: {confirmationCode}</div>
                <div className="CostBreakDown">
                    <div className="CostRow">
                        <div className="CostCell">TuxRydes Fee</div>
                        <div className="CostCell">{priceFormatter.format(fee)}</div>
                    </div>
                </div>
                <div className="CostTotal">
                    <div className="CostRow">
                        <div className="CostCell">CC (Paid)</div>
                        <div className="CostCell">{priceFormatter.format(price)}</div>
                    </div>
                </div>
                {
                    legs.map( (leg, i) => {
                        return (
                        <div>
                            <h4>Ride {i + 1}</h4>
                            <RideMap from={leg.fromPlaceId} to={leg.toPlaceId} duration={leg.estimatedDuration.value}/>
                            <h4>Pick-up: {printDate(leg.pickUpDateTime)}</h4>
                            <p>{leg.from}</p>
                            <h4>Drop-off</h4>
                            <p>{leg.to}</p>
                            <h4>Estimated Duration</h4>
                            <p>{leg.estimatedDuration.text}</p>
                        </div>
                        );
                    })
                }
                <div className="ConfirmContact">For assistance with this reservation, call: {PHONE}</div>
            </div>
            )}
    </ThemeProvider>
    </div>
  );
}

export default BookRide;
