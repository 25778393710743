import './RideLeg.css';
import blackCar from './images/black-white-car.png';
import blackSuv from './images/black-white-suv.png';
import blackVan from './images/black-white-van.png';
import blackBus from './images/black-white-bus.png';
import React, { useState, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import AutoCompleteTextField from './AutoCompleteTextField';
import { VIEWPORT_LIMIT, MAX_PASSENGERS, MAX_BAGS, MAX_NON_STANDARD_BAGS, MULTIPLIER_MAX_PASSENGERS, MULTIPLIER_MAX_PASSENGERS_ORIGINAL } from '../libs/constants.js';
import { getVehicleType, getInitalDate } from '../libs/utils.js';

const routeService = { current: null };

function RideLeg(props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeZone = props.region === 'ja'? 'Jamaica': 'America/Los_Angeles';
    const initialDay = getInitalDate(props.region).add(2, 'hour').tz(timeZone);
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [pickUp, setPickUp] = useState(props?.pickUp || null);
    const [dropOff, setDropOff] = useState(props?.dropOff || null);
    const [pickUpPlaceId, setPickUpPlaceId] = useState(props?.pickUpPlaceId || null);
    const [dropOffPlaceId, setDropOffPlaceId] = useState(props?.dropOffPlaceId || null);
    const [date, setDate] = useState(initialDay);
    const [time, setTime] = useState(initialDay);
    const [passengers, setPassengers] = useState(1);
    const [bags, setBags] = useState(1);
    const [overSizedBags, setOverSizedBags] = useState(0);
    const [irregularBags, setIrregularBags] = useState(0);
    const [allDay, setAllDay] = useState(false);
    const [passengersAnchor, setPassengersAnchor] = useState(null);

    const handlePassengersClick = (event) => {
        setPassengersAnchor(passengersAnchor ? null : event.currentTarget);
    };

    const passengersOpen = Boolean(passengersAnchor);
    const passengersId = passengersOpen ? 'passengers-popper' : undefined;

    const [bagsAnchor, setBagsAnchor] = useState(null);

    const [estimatedDuration, setEstimatedDuration] = useState(null);
    const map = useMap();
    const routeLib = useMapsLibrary('routes');

    const handleBagsClick = (event) => {
        setBagsAnchor(bagsAnchor ? null : event.currentTarget);
    };

    const updateEstimatedDuration = () => {
        if (!routeLib) {
            return;
        }

        if (!routeService.current) {
            routeService.current = new routeLib.DistanceMatrixService();
        }

        if (!routeService.current) {
            return;
        }

        if (dropOffPlaceId === null || pickUpPlaceId === null ||
           dropOffPlaceId === 'null' || pickUpPlaceId === 'null' ||
           dropOffPlaceId === undefined || pickUpPlaceId === undefined) {
            setEstimatedDuration({});
            onChange({updatedEstimatedDuration: {}});
            return;
        }

        routeService.current.getDistanceMatrix({
            destinations: [{placeId: dropOffPlaceId}],
            origins: [{placeId: pickUpPlaceId}],
            travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                const duration = response.rows[0].elements[0].duration;
                setEstimatedDuration(duration);
                onChange({updatedEstimatedDuration: duration});
            });

    };

    const bagsOpen = Boolean(bagsAnchor);
    const bagsId = bagsOpen ? 'bags-popper' : undefined;

    const getRideLegInfo = (updated) => {
        return {
            from: updated?.updatedFrom || pickUp,
            fromPlaceId: updated?.updatedFromPlaceId || pickUpPlaceId,
            to: updated?.updatedTo || dropOff,
            toPlaceId: updated?.updatedToPlaceId || dropOffPlaceId,
            date: updated?.updatedDate || date,
            time: updated?.updatedTime || time,
            passengers: updated?.updatedPassengers || passengers,
            bags: {
                luggage: updated?.updatedBags || bags,
                overSizedBags: updated?.updatedOverSizedBags || overSizedBags,
                irregularBags: updated?.updatedIrregularBags || irregularBags
                },
            allDay: ('updatedAllDay' in updated) ? updated.updatedAllDay : allDay,
            estimatedDuration: updated?.updatedEstimatedDuration ||estimatedDuration
            };
    };

    const onChange = (updated) => {
        if (props.onChange) {
            const rideLegInfo = getRideLegInfo(updated);
            props.onChange(rideLegInfo);
        }
    };

    const onChangeTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setTime(newTime);
          onChange({updatedTime: newTime});
        }
    };

    const onChangeDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setDate(newDate);
          onChange({updatedDate: newDate});
        }
    };

    const incPassengers = () => {
        if (passengers < MAX_PASSENGERS) {
            setPassengers(passengers + 1);
            onChange({updatedPassengers: passengers + 1});
        }
    };

    const decPassengers = () => {
        if (passengers > 1) {
            setPassengers(passengers - 1);
            onChange({updatedPassengers: passengers - 1});
        }
    };

    const incBags = () => {
        if (bags < MAX_BAGS) {
            setBags(bags + 1);
            onChange({updatedBags: bags + 1});
        }
    };

    const decBags = () => {
        if (bags > 0) {
            setBags(bags - 1);
            onChange({updatedBags: bags - 1});
        }
    };

    const incOverSizeBags = () => {
        if (overSizedBags < MAX_NON_STANDARD_BAGS) {
            setOverSizedBags(overSizedBags + 1);
            onChange({updatedOverSizedBags: overSizedBags + 1});
        }
    };

    const decOverSizeBags = () => {
        if (overSizedBags > 0) {
            setOverSizedBags(overSizedBags - 1);
            onChange({updatedOverSizedBags: overSizedBags - 1});
        }
    };

    const incIrregularBags = () => {
        if (irregularBags < MAX_NON_STANDARD_BAGS) {
            setIrregularBags(irregularBags + 1);
            onChange({updatedIrregularBags: irregularBags + 1});
        }
    };

    const decIrregularBags = () => {
        if (irregularBags > 0) {
            setIrregularBags(irregularBags - 1);
            onChange({updatedIrregularBags: irregularBags - 1});
        }
    };

    const setFrom = (from) => {
        setPickUp(from);
        onChange({updatedFrom: from});
    };

    const setTo = (to) => {
        setDropOff(to);
        onChange({updatedTo: to});
    };

    const setFromPlaceId = (placeId) => {
        setPickUpPlaceId(placeId);
        onChange({updatedFromPlaceId: placeId});
    };

    const setToPlaceId = (placeId) => {
        setDropOffPlaceId(placeId);
        onChange({updatedToPlaceId: placeId});
    };

    const getVehicleImage = (passengers) => {
        const vehicleType = getVehicleType(passengers);
        if (vehicleType === 'car') {
            return blackCar;
        } else if (vehicleType === 'suv') {
            return blackSuv;
        } else if (vehicleType === 'van') {
            return blackVan;
        } else if (vehicleType === 'bus') {
            return blackBus;
        }
    };

    const getVehicleTypeImage = (requestedVvehicleType) => {
        const vehicleType = getVehicleType(passengers);
        // return requestedVvehicleType === 'bus' ? blackBus :  null /* other value or null */;

        // return requestedVvehicleType;
        if (vehicleType === 'car') {
            return blackCar;
        } else if (vehicleType === 'suv') {
            return blackSuv;
        } else if (vehicleType === 'van') {
            return blackVan;
        } else if (vehicleType === 'bus') {
            return blackBus;
        }
    };

    let currentVehicleIndex = 0; // Start with the first vehicle type

    // Function to get the current vehicle type image
    const getCurrentVehicleTypeImage = () => {
        return vehicleTypes[currentVehicleIndex].image;
    };
    // Array of vehicle types and their corresponding images
    const vehicleTypes = [
        { type: 'car', image: blackCar },
        { type: 'suv', image: blackSuv },
        { type: 'van', image: blackVan },
        { type: 'bus', image: blackBus }
    ];

    const handleVehicleTypeClick = () => {
       // getVehicleTypeImage('bus');
       // getCurrentVehicleTypeImage();
       // const vheicleImage = getCurrentVehicleTypeImage;
        //getVehicleTypeImage('bus');
        // alert('Button clicked!');
    };


    useEffect( () => {
        updateEstimatedDuration();
    }, [pickUpPlaceId, dropOffPlaceId, routeLib])

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    return (
    <div className="RideLeg">
        <Stack spacing={2} direction="column">
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <AutoCompleteTextField key="ride-leg-pickup" label="Pickup Location" value={pickUp} setValue={setFrom} setPlaceId={setFromPlaceId} region={props.region}/>
                <AutoCompleteTextField key="ride-leg-dropoff" label="Drop-off Location" value={dropOff} setValue={setTo} setPlaceId={setToPlaceId} region={props.region !== 'sac' && props.region !== 'abq' ? props.region : undefined }/>
                <DatePicker label="Pickup Date" variant="filled" onChange={onChangeDate} value={date} minDate={initialDay}/>
                <TimePicker label="Pickup Time" variant="filled" onChange={onChangeTime} value={time} />
            </Stack>
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <div className="PassengerOuter">
                    <div className="PassengerLabel">Passengers</div>
                    <div className="Passenger">
                        <Button onClick={decPassengers} style={{color: 'black'}}><RemoveIcon/></Button>
                        {passengers}
                        <img src={getVehicleImage(passengers)} style={{height: 35, width: 70}}/>
                        <Button onClick={incPassengers} style={{color: 'black'}}><AddIcon/></Button>
                    </div>
                </div>
                
                <div className="VehicleTypeOuter">
                    <div className="VehicleTypeLabel">Vehicle Type</div>
                    <div className="VehicleType">
                        <Button onClick={handleVehicleTypeClick(passengers)} style={{color: 'black'}}>
                            <img src={getVehicleTypeImage()} alt="Vehicle Type" style={{height: 35, width: 70}} />
                        </Button>
                    </div>
                </div>

                <div className="PopupButton">
                    <div className="BagsOuter">
                        <div className="BagsLabel">Bags</div>
                        <div className="Bags">
                            <span>{bags + overSizedBags + irregularBags}</span>
                            <Button onClick={handleBagsClick} style={{color: 'black'}}>
                                { !bagsOpen && (<KeyboardArrowDownIcon />) }
                                { bagsOpen && (<KeyboardArrowUpIcon/>)}
                            </Button>
                        </div>
                    </div>
                    <BasePopup id={bagsId} open={bagsOpen} anchor={bagsAnchor}>
                        <ClickAwayListener onClickAway={() => {setBagsAnchor(null);}}>
                            <div className="Popup">
                                <Stack direction="column">
                                    <div>
                                        <div><span className="InfoTitle">Luggage</span><Button onClick={decBags} style={{color: 'black'}}><RemoveIcon/></Button>{bags}<Button onClick={incBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Suitcases, Backpacks, etc.</div>
                                    </div>
                                    <div>
                                        <div><span className="InfoTitle">Oversized</span><Button onClick={decOverSizeBags} style={{color: 'black'}}><RemoveIcon/></Button>{overSizedBags}<Button onClick={incOverSizeBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Video Equipment, Boxes, etc.</div>
                                    </div>
                                    <div>
                                        <div><span className="InfoTitle">Irregular</span><Button onClick={decIrregularBags} style={{color: 'black'}}><RemoveIcon/></Button>{irregularBags}<Button onClick={incIrregularBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Golf Clubs, Strollers, etc.</div>
                                    </div>
                                </Stack>
                            </div>
                        </ClickAwayListener>
                    </BasePopup>
                </div>

                <div className="AllDay">
                    <Checkbox checked={allDay}  onChange={(event) => { setAllDay(event.target.checked); onChange({updatedAllDay: event.target.checked}); }} /> All Day Excursion
                </div>
                {estimatedDuration && estimatedDuration.text && (<div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>)}
            </Stack>
        </Stack>
    </div>
    );
}

export default RideLeg;
